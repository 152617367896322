<template>
  <el-main>
    <el-button style="margin-bottom: 10px" @click="add" size="small" type="primary" icon="el-icon-plus">添加员工</el-button>
    <!-- 表单 -->
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="员工名称：">
        <el-input size="small" placeholder="请输入员工名称" v-model="searchForm.service_name"></el-input>
      </el-form-item>
       <el-form-item label="员工电话：">
        <el-input size="small" placeholder="请输入员工电话" v-model="searchForm.service_phone"></el-input>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker size="small"
        v-model="searchForm.create_time"
        type="daterange"
        range-separator="~"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp">
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>

    <!-- 列表 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="displayorder" align="center" width="120">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
         <template slot-scope="scope">
          <el-input type="number" :min="0" size="small" v-model="scope.row.displayorder" @change="editInfo(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="service_name" label="员工姓名" align="center"></el-table-column>
      <el-table-column  label="员工信息" min-width="200">
          <template v-slot="{ row }">
            <div class="userInfo">
                <el-image :src="row.avatar"></el-image>
                <div class="flex1">{{row.nickname}}</div>
            </div>
         </template>
      </el-table-column>
      <el-table-column prop="mobile" label="员工电话" align="center"  width="120">
      </el-table-column>
      <el-table-column prop="pre_service_num" label="单位时间服务量" align="center" min-width="150">
      </el-table-column>
      <el-table-column prop="service_user_num" label="服务用户数" align="center" min-width="120">
      </el-table-column>
      <el-table-column prop="total_order_count" label="累计接单数" align="center" min-width="120">
      </el-table-column>
      <el-table-column prop="total_service_type_num" label="已关联服务" align="center" min-width="120">
      </el-table-column>
      <el-table-column prop="create_time" label="添加时间" align="center" width="180">
           <template v-slot="{ row }">{{row.create_time|getDateformat}}</template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button type="text" size="small" @click="relationService(scope.row)">关联服务</el-button>
          <el-button type="text" size="small" @click="delStall(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>

    <!-- 添加员工 -->
    <el-dialog :title="(type=='add'?'添加':'编辑')+'员工'" :visible.sync="dialogVisible" width="50%">
       <el-form label-width="140px" label-position="left">
            <el-form-item label="员工姓名：" required>
                <el-input size="small" placeholder="请输入员工姓名" v-model="employee_form.service_name"></el-input>
            </el-form-item>
            <el-form-item label="权重：" style="margin-left:10px;" label-width="130px">
                <el-input size="small" placeholder="请输入权重" v-model="employee_form.displayorder"></el-input>
            </el-form-item>
            <el-form-item label="选择用户：" required>
                <el-input size="small" v-model="employee_form.user_id" disabled style="width:calc(100% - 56px);"></el-input>
                <el-button type="primary" size="small" @click="showSelectUser = true" :disabled="type=='edit'">选择</el-button>
            </el-form-item>
            <el-form-item label="员工头像：" required>
                <ReadyUploadSource
                @getSource="getGoodsImg"
                @removeThis="removeImageThis"
                :path="employee_form.image"
              ></ReadyUploadSource>
            </el-form-item>
             <el-form-item label="员工职位：" required>
                <el-input size="small" placeholder="请输入员工职位" v-model="employee_form.position"></el-input>
            </el-form-item>
            <el-form-item label="员工电话：" required>
                <el-input size="small" placeholder="请输入员工电话" v-model="employee_form.service_phone"></el-input>
            </el-form-item>
            <el-form-item label="单位时间服务量：" required>
                <el-input size="small" placeholder="请输入单位时间服务量" v-model="employee_form.pre_service_num">
                    <div slot="append">{{interval | intervalLabel}}</div>
                </el-input>
                <div style="line-height:30px;">单位时间服务量，为单位时间内（15分钟/30分钟/1小时/2小时）此员工可以服务的客户数量，可以在预约设置内，修改单位时间量，<el-link :underline="false" type="primary" @click="toSet">去设置</el-link></div>
            </el-form-item>
            <el-form-item label="关联服务：" required>
                <div v-if="employee_form.relation_service && employee_form.relation_service.length>0" style="margin-right:20px;display:inline-block;">已关联{{employee_form.relation_service.length}}个</div>
                <el-link :underline="false" type="primary" @click="relationService(null)">+ 关联服务</el-link>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmForm">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 选择用户 -->
    <el-dialog title="选择用户" :visible.sync="showSelectUser" width="54%">
        <el-form class="el-form-search" label-width="110px">
            <el-form-item label="用户昵称：">
                <el-input size="small" placeholder="请输入用户昵称" v-model="user_form.nickname"></el-input>
            </el-form-item>
            <el-form-item label="用户电话：">
                <el-input size="small" placeholder="请输入用户电话" v-model="searchForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="searchUser">搜索</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="user_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="avatar" label="用户头像" align="center">
                <template slot-scope="scope">
                    <el-image :src="scope.row.avatar" class="avatar"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="nickname" label="用户昵称" align="center"></el-table-column>
            <el-table-column prop="mobile" label="用户电话" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="chooseUser(scope.row.id)" type="text" size="small">选择</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="user_total_number" :page="user_form.page" :pageNum="user_form.rows" @updatePageNum="updateDataUser"></Paging>
    </el-dialog>

    <!-- 关联服务 -->
    <el-dialog title="关联服务" :visible.sync="showAddServe" width="66%">
        <div class="serve_main">
            <div class="serve_main_left">
                <div class="row1">员工 {{employee_form.service_name}} 可选择关联的服务</div>
                <div class="row2">
                    待选
                </div>
                <div class="row3">
                    <div class="classify">
                        <div class="classify_all" @click="findServe('')">全部</div>
                         <ul  v-infinite-scroll="loadClassify" class="classify_list" :infinite-scroll-disabled="classify_disabled">
                            <li v-for="item in classify_list" :key="item.id" @click="findServe(item.id)" :style="{'color': serve_form.classify_id==item.id?'#4796fe':'#333333'}">{{item.classify_name}}</li>
                        </ul>
                    </div>
                   <div class="serve">
                        <div class="serve_all">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">服务名称</el-checkbox>
                        </div>
                        <el-checkbox-group v-model="checked_serves" @change="handleCheckedServesChange">
                            <ul class="serve_list">
                                <li v-for="item in serve_list" :key="item.id">
                                    <el-checkbox :label="item.id">{{item.service_name}}</el-checkbox>
                                </li>
                                <li v-if="serve_empty" style="font-size: 14px;text-align:center;">暂无内容</li>
                            </ul>
                        </el-checkbox-group>
                   </div>
                </div>
            </div>
            <div class="serve_main_add">
                <el-button type="primary" @click="addServe">添加</el-button>
            </div>
            <div class="serve_main_right">
                <div class="row1">
                    <el-input size="small" placeholder="请输入服务名称" v-model="serve_form.service_name"></el-input>
                    <el-button type="primary" size="small" @click="searchServe">搜索</el-button>
                </div>
                 <div class="row2">
                    <div>已选</div>
                    <div class="clear" @click="clearServe">清空</div>
                </div>
                <div class="row3">
                    <div class="serve_title">
                        <div>服务名称</div>
                        <div>操作</div>
                    </div>
                    <ul class="serve_list">
                        <li class="serve_list_item" v-for="(item,index) in choose_serves" :key="item.id">
                            <div>{{item.service_name}}</div>
                            <el-button type="text" @click="delServe(index)">删除</el-button>
                        </li>
                        <li class="serve_list_empty" v-if="choose_serves.length==0">暂无内容</li>
                    </ul>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showAddServe = false">取 消</el-button>
            <el-button type="primary" @click="confirmAddServe">确 定</el-button>
        </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource
  },
  data() {
    return {
        interval:'',
        key:new Date(),

        searchForm: {
            page: 1,
            rows: 10,
            service_name: '',
            service_phone: '',
            create_time:''
        },
        total_number:0,
        type:'add',
        dialogVisible:false,

        employee_form:{
            service_name:'',
            displayorder:0,
            service_phone:'',
            pre_service_num:'',
            relation_service:[],
            user_id:'',
            image:'',
            position:''
        },

        showSelectUser:false,
        user_form:{
            page: 1,
            rows: 5,
            nickname:"",
            mobile:''
        },
        user_list:[],
        user_total_number:0,

        showAddServe:false,
        classify_list:[],
        classify_form:{
            page: 1,
            rows: 10
        },
        classify_disabled:false,

        serve_form:{
            classify_id:"",
            service_name:'',
            staff_id:''
        },
        serve_list:[],
        isIndeterminate:false,
        checkAll:false,
        checked_serves:[],
        serve_empty:false,
        serve_type:1,

        choose_serves:[]
    };
  },
  filters:{
      getDateformat:getDateformat,
      intervalLabel(val){
            switch (val){
                case 15:
                    return '15分钟'
                    break
                case 30:
                    return '30分钟'
                    break
                default:
                    return val/60 + '小时'
                    break
            }
        },
  },
  created() {
    this.getList();
    this.getUserList()
    this.getClassifyList()
    this.getInterval()
  },
  methods: {
      getInterval(){
          this.$axios.post(this.$api.beauty.setinfo)
        .then(res => {
          if (res.code == 0) {
            if(res.result){
                this.interval = res.result.appointment_interval_time
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      updateData(val, status){
        if (status == 0) {
            this.searchForm.rows = val;
            this.getList();
        } else {
            this.searchForm.page = val;
            this.getList();
        }
    },
    updateDataUser(val, status){
        if (status == 0) {
            this.user_form.rows = val;
            this.getUserList();
        } else {
            this.user_form.page = val;
            this.getUserList();
        }
    },
    cancelSearch() {
        this.searchForm = {
             page: 1,
            rows: 10,
            service_name: '',
            service_phone: '',
            create_time:''
        };
        this.getList();
    },
    getList() {
        let data = Object.assign({}, this.searchForm)
        if(data.create_time){
            data.create_time[0] = data.create_time[0]/1000
            data.create_time[1] = data.create_time[1]/1000
        }
      this.$axios.post(this.$api.beauty.stafflist, data).then(res => {
        if (res.code == 0) {
          this.total_number = res.result.total;
          this.key = new Date()
          this.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    add() {
        this.type = 'add'
        this.employee_form = {
            service_name:'',
            displayorder:0,
            service_phone:'',
            pre_service_num:'',
            relation_service:'',
            user_id:'',
            image:'',
            position:''
        }
        this.dialogVisible = true
    },
    edit(row) {
        this.type = 'edit'
        this.employee_form = {
            service_name:row.service_name,
            displayorder:row.displayorder,
            service_phone:row.service_phone,
            pre_service_num:row.pre_service_num,
            relation_service:row.relation_service?row.relation_service.split(','):'',
            user_id:row.user_id,
            id:row.id,
            image:row.image,
            position:row.position
        }
        this.dialogVisible = true
    },
    getUserList(){
        this.$axios.post(this.$api.beauty.userCanList, this.user_form).then(res => {
            if (res.code == 0) {
                this.user_list = res.result.list
                this.user_total_number = res.result.total_number
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    confirmForm(){
        if(!this.employee_form.service_name || !this.employee_form.service_phone || !this.employee_form.pre_service_num || !this.employee_form.relation_service || !this.employee_form.user_id || !this.employee_form.image || !this.employee_form.position){
            return this.$message.warning('请将带*号的填写完整');
        }
        let url = this.type=='add'?this.$api.beauty.addStaff:this.$api.beauty.editStaff
        this.$axios.post(url, this.employee_form).then(res => {
            if (res.code == 0) {
                let msg = this.type=='add'?'添加成功':'编辑成功'
                this.$message.success(msg);
                this.dialogVisible = false
                this.getList();
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    editInfo(row){
        let data = {
            service_name:row.service_name,
            displayorder:row.displayorder,
            service_phone:row.service_phone,
            pre_service_num:row.pre_service_num,
            relation_service:row.relation_service?row.relation_service.split(','):'',
            user_id:row.user_id,
            id:row.id
        }
        this.$axios.post(this.$api.beauty.editStaff, data).then(res => {
            if (res.code == 0) {
                this.$message.success('编辑成功');
                this.getList();
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    toSet(){
        this.$router.push({path:'/reserve/reserveSet'})
    },
    searchUser(){
        this.searchForm.page = 1
        this.getUserList()
    },
    chooseUser(id){
        this.employee_form.user_id = id
        this.showSelectUser = false
    },
    delStall(id){
        this.$confirm('确定要删除此员工?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            this.$axios.post(this.$api.beauty.delStaff, {id}).then(res => {
                if (res.code == 0) {
                    this.$message.success('删除成功');
                    this.getList();
                }else{
                    this.$message.error(res.msg);
                }
            });
        })
        .catch(() => {});
    },
    async relationService(row=null){
        if(row){
            this.employee_form = {
                service_name:row.service_name,
                displayorder:row.displayorder,
                service_phone:row.service_phone,
                pre_service_num:row.pre_service_num,
                relation_service:row.relation_service?row.relation_service.split(','):'',
                id:row.id
            }
            this.serve_type = 0
        }else{
            this.serve_type = 1
        }
        this.serve_form.classify_id = ''
        this.serve_form.staff_id = this.employee_form.id?this.employee_form.id:''
        this.serve_list = []
        this.serve_empty = false
        this.choose_serves = []

        await this.getServiceList()

        this.isIndeterminate = false
        this.checkAll = false
        this.checked_serves = []
        this.showAddServe = true
    },
    confirmAddServe(){
         this.employee_form.relation_service = this.choose_serves.length>0?this.choose_serves.map(item=>item.id):''
        if(this.serve_type){
            this.showAddServe = false
        }else{
            this.$axios.post(this.$api.beauty.editStaff, this.employee_form).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功');
                    this.showAddServe = false
                    this.getList();
                }else{
                    this.$message.error(res.msg);
                }
            });
        }
    },
    getClassifyList(){
        this.$axios.post(this.$api.beauty.classifyList,this.classify_form).then(res => {
            if (res.code == 0) {
                this.classify_disabled = res.result.list.length<10
                this.classify_list = [...this.classify_list,...res.result.list]
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    searchServe(){
        this.serve_list = []
        this.serve_empty = false
        this.getServiceList()
    },
    getServiceList(){
        return this.$axios.post(this.$api.beauty.serviceList,this.serve_form).then(res => {
            if (res.code == 0) {
                this.serve_list = res.result.list.list
                let choose_serves = res.result.not_in_list.list?res.result.not_in_list.list:[]
                console.log(this.choose_serves,choose_serves)
                let arr = [...this.choose_serves,...choose_serves]
                this.choose_serves = arr.filter((x, index,self)=>self.findIndex(n=>n.id==x.id) === index)
                this.serve_empty = this.serve_list.length==0
            }else{
                this.$message.error(res.msg);
            }
        });
    },
    loadClassify(){
        this.classify_form.page += 1
        this.getClassifyList()
    },
    handleCheckAllChange(val){
        let values = this.serve_list.map(item=>{
            return item.id
        })
        this.checked_serves = val ? values : [];
        this.isIndeterminate = false;
    },
    handleCheckedServesChange(value){
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.serve_list.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.serve_list.length;
    },
    addServe(){
        let checked_arr = this.checked_serves.map(item=>{
            return this.serve_list.find(x=>x.id==item)
        })
        let arr = [...this.choose_serves,...checked_arr]
        this.choose_serves = arr.filter((x, index,self)=>self.findIndex(n=>n.id==x.id) === index)
    },
    delServe(index){
        this.$confirm('确定要删除此项?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            this.choose_serves.splice(index,1)
        })
        .catch(() => {});
    },
    clearServe(){
        this.$confirm('确定要清空所有选择项?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            this.choose_serves = []
        })
        .catch(() => {});
    },
    findServe(id){
        this.serve_form.classify_id = id
        this.searchServe()
    },
    getGoodsImg(val) {
      this.employee_form.image = val.path;
    },
    removeImageThis(){
        this.employee_form.image = ""
    }
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.el-table {
  .userInfo {
    display: flex;
    align-items: center;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.avatar{
    width: 60px;
    height: 60px;
}
.edit{
    display: flex;
    align-items: center;
    justify-content: center;
    i{
        font-size:17px;
        margin-left:4px;
    }
}

.serve_main{
    display: flex;
    align-items: center;
    .serve_main_left{
        flex: 1;
        .row3{
            display: flex;
            .classify{
                width: 30%;
                margin-right: 20px;
                .classify_all{
                    height: 30px;
                    line-height: 30px;
                    background-color: #4796fe;
                    color: #fff;
                    border-radius: 4px;
                    cursor: pointer;
                    padding: 0 10px;
                }
                .classify_list{
                    height: 410px;
                    overflow: auto;
                     &::-webkit-scrollbar{
                        width: 4px;
                    }
                    scrollbar-width: thin;

                    li{
                        padding: 0 10px;
                        cursor: pointer;
                        height: 40px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .serve{
                flex:1;
                width: 0;
                border: 1px solid #e0e0e0;
                .serve_all{
                    background-color: #f0f0f0;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 10px;
                    border-bottom: 1px solid #e0e0e0;
                }
                .serve_list{
                    // height: 400px;
                    // overflow: auto;
                    //  &::-webkit-scrollbar{
                    //     width: 4px;
                    // }
                    // scrollbar-width: thin;
                    li{
                        height: 40px;
                        line-height: 40px;
                        padding: 0 10px;
                        border-bottom: 1px solid #e0e0e0;
                        &:last-child{
                            border-bottom:none;
                        }
                    }
                    /deep/.el-checkbox{
                        width: 100%;
                         white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        .el-checkbox__label{
                            display: inline;
                        }
                    }
                }
            }
        }
    }
    .serve_main_add{
        text-align: center;
        margin: 0 30px;
    }
    .serve_main_right{
        width: 48%;
        .row3{
            border: 1px solid #e0e0e0;
            .serve_title{
                background-color: #f0f0f0;
                height: 40px;
                line-height: 40px;
                padding: 0 30px;
                border-bottom: 1px solid #e0e0e0;
                display: flex;
                justify-content: space-between;
            }
            .serve_list{
                height: 400px;
                .serve_list_item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    padding: 0 30px;
                    border-bottom: 1px solid #e0e0e0;
                }
                .serve_list_empty{
                    height: 40px;
                    line-height: 40px;
                    padding: 0 30px;
                    font-size: 14px;
                    text-align:center;
                    border-bottom: 1px solid #e0e0e0;
                }
            }
        }

    }
    .row1{
        height: 60px;
        display: flex;
        align-items: center;
        .el-input{
            flex:1;
        }
    }
    .row2{
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: #4796fe;
        color: #fff;
        border-radius: 4px;
        justify-content: space-between;
        .clear:hover{
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .row3{
        margin-top: 20px;
    }

}
</style>
